@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/functions';

.tp-footer {
  font-size: 16px;
  padding: 2rem 0 2rem 0;
  text-align: center;

  @include bp-gte(sm) {
    padding: 12rem 0 8rem 0;
    text-align: left;
  }

  h2 {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: rem(24px);
    font-size: rem(18px);
    font-weight: 900;
  }

  .tp-footer-widget ul li a span {
    color: rgba(255, 255, 255, 0.7);
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  a {
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: $white;
    }
  }

  .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  &__social {
    padding-top: rem(24px);
  }

  &__social-list {
    list-style: none;
    margin: 0 16px 0 0;
    display: inline-block;

    &__link {
      height: 50px;
      width: 50px;
      display: block;
      float: left;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 50%;
      position: relative;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      padding: 13px;

      svg {
        width: 100%;
        height: auto;

        path {
          fill: rgba(255, 255, 255, 0.7)!important;
        }
      }

      &:hover {
        color: $white;

        svg path {
          fill: $white!important;
        }
      }

      span {
        position: absolute;
        font-size: 26px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }

  &__copyright {
    margin-top: 1rem;
  }
}

.footer-small-nav > li {
  display: inline-block;
}

.footer-small-nav > li a {
  margin: 0 10px 10px 0;
}
