@import '../scss/mixins';
@import '../scss/functions';
@import '../scss/variables';

.tp-post {
  margin-bottom: rem(64px);

  @include bp-gte(md) {
    padding-bottom: 4rem;
  }

  h1 {
    text-align: left;
    margin-bottom: rem(8px);
    font-size: rem(48px);

    @include bp-gte(md) {
      text-align: center;
    }
  }

  h3 {
    color: $primary-vlight;
    font-size: rem(21px);
    margin-bottom: rem(24px);

    @include bp-gte(md) {
      text-align: center;
      margin-bottom: 3.5rem;
    }
  }

  h4 {
    color: $primary-vlight;
    font-size: rem(21px);
    margin-bottom: rem(16px);

    @include bp-gte(md) {
      margin-bottom: rem(32px);
    }
  }

  a {
    color: $secondary;
    font-weight: bold;
    text-decoration: underline;
  }

  ol {
    margin-bottom: 1rem;
    border-left: 2px solid $grey;
    padding-left: rem(16px);
    margin-left: rem(16px);

    @include bp-gte(md) {
      padding-left: rem(28px);
      margin-left: rem(32px);
    }
  }

  ul {
    margin-bottom: rem(32px);
    list-style: disc;
    padding-left: rem(28px);
  }

  li {
    font-size: rem(18px);
  }

  img,
  iframe {
    width: 100%;
    max-width: rem(600px);
    display: block;
    margin: rem(32px) auto rem(48px);
  }

  img.headerImg {
    margin: 0 auto rem(32px);

    @include bp-gte(md) {
      margin: 0 auto rem(64px);
    }
  }

  hr {
    margin: rem(64px) rem(128px);
  }

  .halfWidth {
    width: 49%;
    display: inline-block;
  }

  .margin-16 {
    margin-bottom: rem(16px);
  }

  .site-link {
    font-size: 1.2rem;
    font-weight: bold;
    margin: rem(40px) 0;

    @include bp-gte(md) {
      margin: rem(48px) 0;
    }

    a {
      color: $secondary;
      border: 1px solid $secondary;
      border-radius: 4px;
      padding: rem(12px);
      text-decoration: none!important;
      transition: color $transition, border $transition;

      &:hover,
      &:active,
      &:focus {
        color: $secondary-dark;
        border: 1px solid $secondary-dark;
      }
    }
  }

  .project-summary {
    border-bottom: 1px solid $grey;
    padding: 32px;
    margin: 32px;
  }
}
