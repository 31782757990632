@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/functions';

.tp-page-wrapper {
  padding-top: rem(78px);

  @include bp-gte(md) {
    padding-top: 8.5rem;
  }

  ul {
    margin-bottom: rem(48px);
  }

  h1 {
    text-align: left;
    margin-bottom: rem(8px);
    font-size: rem(48px);

    @include bp-gte(md) {
      text-align: center;
    }
  }

  h2 {
    margin-bottom: rem(16px);

    @include bp-gte(md) {
      margin-bottom: 1.5rem;
    }
  }

  h3 {
    color: $primary-vlight;
    font-size: rem(21px);
    margin-bottom: rem(16px);

    @include bp-gte(md) {
      text-align: center;
      margin-bottom: rem(32px);
    }
  }

  h4 {
    color: $primary-vlight;
    font-size: rem(21px);
    margin-bottom: rem(16px);
  }
}
