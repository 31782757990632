@import '../scss/variables';

.tp-app {

  &--home {
    .tp-app__top-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  > .makeStyles-root-251 {
    z-index: 4;
  }
}
