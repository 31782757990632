@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/functions';

.tp-companies {
  background-color: $primary-vlight;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: rem(64px) rem(16px);

  @include bp-gte(md) {
    padding: rem(84px) rem(84px) rem(104px);
  }

  h2 {
    font-size: rem(21px);
    margin-bottom: rem(32px);
    text-align: center;
    color: #ffffffa8;

    @include bp-gte(md) {
    }
  }

  li {
    vertical-align: top;
    text-align: center;
    margin-bottom: rem(24px);
    width: auto;

    @include bp-gte(md) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: rem(64px);

      &:last-child {
        margin: 0;
      }
    }

    img {
      height: rem(32px);
      margin: 0;
    }
  }
}
