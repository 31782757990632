@import '../scss/variables';
@import '../scss/functions';
@import '../scss/mixins';


.tp-portfolio-gallery {
  padding-bottom: rem(64px);
  animation-name: fadeInUp;
  animation-duration: 1000ms;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0.1;
    transform: translate3d(0, 5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}
