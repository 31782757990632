$font-primary: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-base: 16px;

$white: #FCFCFC;
$black: #05090F;
$grey: #BCB9C1;
$grey-dark: #393F52;
// $primary: #1541B7;
// $triadic1: #8c15b7;
// $triadic2: #b71540;
// $tertiary: #1591b7;
// $quarternary: #3b15b7;

$primary: #132B4B;
$secondary: #9ACABE;
$tertiary: #F8C7B7;
$quarternary: #CE7552;
$triadic1: #748eb1;
$triadic2: #748eb1;
$warning: #b78c15;

$secondary-dark: #4f9684;

$primary-light: rgb(66, 85, 111);
$primary-vlight: #65696f;
$primary-dark: rgb(13, 30, 52);

$bg-light: #f8faff;

$transition: 0.1s ease-out;

$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1920px;

$transition-time: 300ms;

$breakpoint-xxs: $screen-xs; // 1
$breakpoint-xs: $screen-sm; // 2
$breakpoint-sm: $screen-md; // 3
$breakpoint-md: $screen-lg; // 4
$breakpoint-lg: $screen-xl; // 5

$breakpoint1: 'only screen and (min-width:#{$breakpoint-xxs}) and (max-width:#{$breakpoint-xs - 1})';
$breakpoint2: 'only screen and (min-width:#{$breakpoint-xs}) and (max-width:#{$breakpoint-sm - 1})';
$breakpoint3: 'only screen and (min-width:#{$breakpoint-sm}) and (max-width:#{$breakpoint-md - 1})';
$breakpoint4: 'only screen and (min-width:#{$breakpoint-md}) and (max-width:#{$breakpoint-lg - 1})';
$breakpoint5: 'only screen and (min-width:#{$breakpoint-lg})';

$breakpoint1-gte: 'only screen and (min-width:#{$breakpoint-xxs})';
$breakpoint2-gte: 'only screen and (min-width:#{$breakpoint-xs})';
$breakpoint3-gte: 'only screen and (min-width:#{$breakpoint-sm})';
$breakpoint4-gte: 'only screen and (min-width:#{$breakpoint-md})';
$breakpoint5-gte: 'only screen and (min-width:#{$breakpoint-lg})';

$breakpoint1-lte: 'only screen and (max-width:#{$breakpoint-xs - 1})';
$breakpoint2-lte: 'only screen and (max-width:#{$breakpoint-sm - 1})';
$breakpoint3-lte: 'only screen and (max-width:#{$breakpoint-md - 1})';
$breakpoint4-lte: 'only screen and (max-width:#{$breakpoint-lg - 1})';
