@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/functions';

.tp-about-page {
  margin-bottom: rem(64px);
  padding-bottom: rem(32px);

  @include bp-gte(md) {
    padding-bottom: rem(100px);
  }

  &__skill {
    display: inline-block;
    width: rem(100px);
  }
}
