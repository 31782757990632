@import '../scss/variables';
@import '../scss/mixins';
@import '../scss/functions';

.tp-contact-page {

  p {
    margin-bottom: 0;
  }

  &__skill {
    display: inline-block;
    width: rem(100px);
  }

  &__email {
    font-size: rem(24px)!important;
    text-transform: lowercase!important;
    display: inline-block;
    color: $secondary-dark;
    margin: 3rem 0;

    @include bp-gte(md) {
      font-size: rem(32px)!important;
    }
  }

  &__details {
    margin-bottom: rem(16px);

    @include bp-gte(md) {
      margin-bottom: rem(32px)!important;
    }
  }

  &__map {
    display: block;
    background-image: url('../static/oxford2bw.png');
    background-position: 50% 50%;
    background-size: 300%;
    height: rem(240px);

    @include bp-gte(sm) {
      background-size: 200%;
    }

    @include bp-gte(md) {
      background-size: cover;
      height: rem(300px);
    }
  }
}
