@import "./variables";

@mixin bp($breakpoint) {
    @if $breakpoint == "xs" {
        @media #{$breakpoint1} { @content; }
    }

    @if $breakpoint == "sm" {
        @media #{$breakpoint2} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint3} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint4} { @content; }
    }

    @if $breakpoint == "xl" {
        @media #{$breakpoint5} { @content; }
    }
}

@mixin bp-gte($breakpoint) {
    @if $breakpoint == "xs" {
        @media #{$breakpoint1-gte} { @content; }
    }

    @if $breakpoint == "sm" {
        @media #{$breakpoint2-gte} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint3-gte} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint4-gte} { @content; }
    }

    @if $breakpoint == "xl" {
        @media #{$breakpoint5-gte} { @content; }
    }
}

@mixin bp-lte($breakpoint) {
    @if $breakpoint == "xs" {
        @media #{$breakpoint1-lte} { @content; }
    }

    @if $breakpoint == "sm" {
        @media #{$breakpoint2-lte} { @content; }
    }

    @if $breakpoint == "md" {
        @media #{$breakpoint3-lte} { @content; }
    }

    @if $breakpoint == "lg" {
        @media #{$breakpoint4-lte} { @content; }
    }
}

@mixin gradient-background(){
  background: $primary;
  background: -moz-linear-gradient(45deg, $primary 0%, $quarternary 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, $primary), color-stop(100%, $quarternary));
  background: -webkit-linear-gradient(45deg, $primary 0%, $quarternary 100%);
  background: -o-linear-gradient(45deg, $primary 0%, $quarternary 100%);
  background: -ms-linear-gradient(45deg, $primary 0%, $quarternary 100%);
  background: linear-gradient(45deg, $primary 0%, $quarternary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#448ef6', endColorstr='#af05a9', GradientType=1 );
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition:     all $transition ease;
    transition:         all $transition ease;
}
