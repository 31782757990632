@import '../scss/variables';
@import '../scss/functions';
@import '../scss/mixins';

.tp-portfolio-item {
  max-width: rem(345px);
  width: 100%;
  background: #fff;
  display: block;
  cursor: pointer;
  position: relative;
  transition: all .5s ease;

  @include bp-gte(md) {
    margin-bottom: rem(30px);
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 2px 6px 10px -5px rgba(0,0,0,0.5);
  }

  &--align-left,
  &--align-center,
  &--align-right {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  &--align-left {
    @include bp-gte(md) {
      float: left;
      margin-left: inherit;
      margin-right: inherit;
    }
  }

  &--align-center {
    @include bp-gte(md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--align-right {
    @include bp-gte(md) {
      float: right;
      margin-left: inherit;
      margin-right: inherit;
    }
  }

  &__inner {
    display: inline-block;
    width: 100%;
  }

  &__media {
    height: rem(180px);
    width: 100%;
  }

  &__type {
    text-transform: capitalize;
    color: $secondary;
  }

  &__desc {

  }

  &__modal {

    &__separator {
      padding: rem(8px) 0;
    }

    &__header {
      margin-bottom: rem(12px);
    }

    &__text-link {
      border: 2px solid;
      border-radius: 4px;
      padding: 0 0 0 rem(12px);
      margin-bottom: rem(8px);
    }

    &__input {
      width: rem(200px);

      @include bp-gte(md) {
        width: rem(400px);
      }
    }
  }
}

.twitter {
  color: #00acee!important;
}

.facebook {
  color: #3b5998!important;
}

.tp-snackbar {
  .MuiSnackbarContent-root {
    background-color: $primary-light!important;
    font-weight: 600;
  }
}

.tp-modal__paper {
  padding: 1rem 0.7rem!important;
  min-width: rem(305px);

  @include bp-gte(md) {
    padding: 1rem 2rem 1.5rem!important;
  }
}
