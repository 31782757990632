@import '../scss/mixins';
@import '../scss/functions';
@import '../scss/variables';

.tp-title-block {
  display: flex;
  align-items: center;
  position: relative;
  height: 14rem;
  text-align: center;

  @include bp-gte(md) {
    text-align: left;
  }

  @include bp-gte(md) {
    height: rem(400px);
  }

  &__logo {
    width: rem(65px);
    margin-bottom: rem(32px);
  }

  &__name {
    color: $primary;
    font-weight: bold;
    font-size: rem(18px);
    text-transform: capitalize;
    margin-bottom: rem(4px);
  }

  &__description {
    font-size: rem(14px);
    margin: 0;
    color: $primary-vlight;
  }

  &__links {}

  &__pages {
    display: block;
  }

  &__page-link {
    display: inline-block;
    margin-right: rem(12px);

    a {
      color: $primary-vlight;

      span {
        color: $secondary;
      }

      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
