@import '../scss/variables';
@import '../scss/functions';
@import '../scss/mixins';


.tp-modal {

  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__paper {
    background-color: $white;
    border: 1px solid $black;
    border-radius: 4px;
    padding: rem(16px) rem(32px) rem(24px);
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  }
}
